const authRoutes = [
  '/',
  '/login',
  '/login/credentials',
  '/reset',
  '/reset/code',
  '/reset/new',
  '/register',
  '/register/email',
  '/sso/callback',
]

export default defineNuxtRouteMiddleware((to) => {
  const user = useUser()

  //   if its an auth route and user is already logged in, redirect to dashboard
  if (authRoutes.includes(to.path) && user) {
    const redirectTo = useCookie('redirectTo')
    if (redirectTo.value) {
      setTimeout(() => {
        redirectTo.value = null
      }, 0)
      return navigateTo(redirectTo.value)
    }
    return navigateTo('/dashboard')
  }
})
